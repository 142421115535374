import { translate } from '../../../../shared/translate';
import BlobField from '../../../item_EmailTemplate/ComponentField/BlobField';
import { Box, InputAdornment } from '@material-ui/core';
import React from 'react';
import InputField from '../../../../components/FormPageMaker/Fields/InputField';
import InnerSelectField from '../../InnerSelectField';
import { AlignIconWithText } from './text';
import { Icon } from '../../../../components/IconRender';
import FontSizeField from './FontSizeField';
import CheckboxField from '../../../../components/FormPageMaker/Fields/CheckboxField';

export default [
  {
    label: translate.payment_details || '付款資訊',
    value: 'isPaymentData',
    render: ({ value, disabled, onChange }) => {
      return (
        <Box
          px={1}
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FontSizeField
            disabled={disabled}
            value={value?.size}
            onChange={(size) =>
              onChange({
                ...value,
                size,
              })
            }
          />
        </Box>
      );
    },
  },
  {
    label: translate.void,
    value: 'isVoidedMark',
  },
  {
    label: translate.reprint || '重印',
    value: 'isReprintMark',
  },
  {
    label: translate.takeaway || '外賣',
    value: 'isTakeawayMark',
  },
  require('./title').default,
  require('./text').default,
  {
    label: translate.logo,
    value: 'isLogo',
  },
  {
    label: translate.label_value || '標值',
    value: 'isLV',
    render: ({ suggestions, disabled, value, onChange }) => (
      <>
        <Box display={'flex'} alignItems={'center'} pl={1}>
          <FontSizeField
            disabled={disabled}
            value={value?.size}
            onChange={(size) =>
              onChange({
                ...value,
                size,
              })
            }
          />
          <div style={{ flex: 1 }}>
            <BlobField
              placeholder={'Label'}
              suggestions={suggestions}
              disabled={disabled}
              value={value?.label}
              onChange={(label) => {
                onChange({
                  ...value,
                  label,
                });
              }}
            />
          </div>
        </Box>
        <BlobField
          placeholder={'Value'}
          suggestions={suggestions}
          disabled={disabled}
          value={value?.value}
          onChange={(v) => {
            onChange({
              ...value,
              value: v,
            });
          }}
        />
      </>
    ),
  },
  {
    label: translate.cut || '切割',
    value: 'isCut',
  },
  {
    label: translate.divider || '分隔',
    value: 'isDivider',
    render: ({ value, onChange, disabled }) => {
      return (
        <InputField
          style={{ height: '100%' }}
          variant={'standard'}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start" style={{ marginLeft: 8 }}>
                {translate.char || '符號'}
              </InputAdornment>
            ),
            style: { height: '100%' },
          }}
          placeholder={'-'}
          maxLength={1}
          value={value.text}
          onChange={(e) =>
            onChange({
              ...value,
              text: e.target.value,
            })
          }
          disabled={disabled}
        />
      );
    },
  },
  {
    label: translate.product,
    value: 'isItems',
    render: ({ value, disabled, onChange }) => {
      return (
        <Box
          px={1}
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FontSizeField
            disabled={disabled}
            value={value?.size}
            onChange={(size) =>
              onChange({
                ...value,
                size,
              })
            }
          />
          <div style={{ marginLeft: 16 }}>
            <CheckboxField
              disabled={disabled}
              checked={value?.showSKU}
              onChange={() => {
                onChange({
                  ...value,
                  showSKU: !value?.showSKU,
                });
              }}
              text={translate.show_sku || '顯示SKU'}
              textVariant={'body2'}
            />
          </div>
        </Box>
      );
    },
  },
  {
    label: translate.order_data,
    value: 'isOrderData',
    render: ({ value, disabled, onChange }) => {
      return (
        <Box
          px={1}
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FontSizeField
            disabled={disabled}
            value={value?.size}
            onChange={(size) =>
              onChange({
                ...value,
                size,
              })
            }
          />
        </Box>
      );
    },
  },
  {
    label: 'QR Code',
    value: 'isQRCode',
    render: ({ value, disabled, suggestions, onChange }) => {
      return (
        <BlobField
          placeholder={'xxxxxxxxxxxxxx'}
          suggestions={suggestions}
          disabled={disabled}
          value={value?.text}
          onChange={(text) => {
            onChange({
              ...value,
              text,
            });
          }}
        />
      );
    },
  },
  {
    label: translate.html,
    value: 'isHTML',
    render: ({ value, onChange, disabled }) => (
      <BlobField
        disabled={disabled}
        value={value?.text}
        onChange={(text) => {
          onChange({
            ...value,
            text,
          });
        }}
      />
    ),
  },
];

import FormPageMaker from '../../components/FormPageMaker';
import { translate } from '../../shared/translate';
import { CREATE_QUERY } from './query';
import { client } from '../../shared/apollo';

export const RoleOptions = [
  {
    label: translate.customer_view,
    value: 'ChicksStaff',
  },
  {
    label: translate.customer_view_store_manager,
    value: 'ChicksStaffManager',
  },
  {
    label: translate.order_manage,
    value: 'ChicksStaffOrder',
  },
  {
    label: translate.customer_management,
    value: 'ChicksCustomer',
  },
  {
    label: translate.cashier,
    value: 'Cashier',
  },
  {
    label: 'Accountant',
    value: 'Accountant',
  },
  {
    label: 'Owner',
    value: 'Owner',
  },
  {
    label: translate.warehouse,
    value: 'Warehouse',
  },
  {
    label: translate.role_product_and_stock_manage,
    value: 'ProductAndWarehouse',
  },
];

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      submit: CREATE_QUERY,
    },
    tabs: [
      {
        name: translate.login,
        cards: [
          {
            fields: [
              {
                label: translate.icon,
                type: 'image',
                name: 'picture',
              },
              {
                md: 6,
                label: translate.name,
                type: 'text',
                name: 'name',
                required: true,
              },
              {
                md: 4,
                label: translate.role,
                type: 'select',
                name: 'permissions',
                required: true,
                multiple: true,
                options: RoleOptions,
              },
              {
                md: 6,
                block: true,
                label: translate.email,
                type: 'email',
                name: 'email',
                required: true,
              },
              {
                md: 6,
                block: true,
                label: translate.password,
                type: 'password',
                name: 'password',
                required: true,
                inputProps: {
                  pattern: '(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}',
                },
                text: translate.password_remark,
              },
              {
                md: 'auto',
                label: translate.email_verify,
                type: 'checkbox',
                name: 'isEmailVerified',
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    const {
      gql: { submit },
    } = this.state;
    await client.mutate({
      mutation: submit,
      variables: {
        companyId: localStorage.getItem('companyId'),
        input: {
          picture: values.picture || undefined,
          name: values.name,
          permissions: [{ key: localStorage.getItem('companyId') + '/*', value: values.permissions ?? [] }],
          isEmailVerified: values.isEmailVerified,
        },
        credential: {
          email: values.email,
          mobileV2: values.phoneNumber || undefined,
          password: values.password,
        },
      },
    });
    return true;
  };
}

import React from 'react';
import { GET_QUERY } from './query';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { DeliveryMethodProvider, DeliveryNoteStatus } from 'shared/omniwe-types';
import StatusBadge from '../../../components/StatusBadge';
import { Box, Grid, Typography } from '@material-ui/core';
import { removePhoneNumberRegion } from '../../../shared';
import MenuPackingList from './MenuPackingList';
import MenuProcess from './MenuProcess';
import MenuComplete from './MenuComplete';
import EllipsisTypography from '../../../components/EllipsisTypography';
import SfPrintButton from './SfPrintButton';
import PrintOptionsButton from '../../../components/PrintOptionsButton';
import ShipAnyButton from './ShipAnyButton';
import MenuPrintLabel from './MenuPrintLabel';
import FreightAmigoPickupButton from './FreightAmigoPickupButton';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    createUrl: '/delivery_notes/new',
    gql: {
      get: GET_QUERY,
    },
    getRowLink: ({ id }) => ({ pathname: `/delivery_notes/${id}`, state: { title: id } }),
    fields: [
      {
        title: translate.date,
        align: 'left',
        width: 180,
        fieldName: 'createdAt',
        type: 'datetime',
        filter: 'createdAt',
        sortBy: 'createdAt',
      },
      {
        column: false,
        title: translate.shop_name,
        filter: 'shopId',
        filterType: 'shop',
      },
      {
        title: translate.delivery_country,
        filter: 'address.country',
        filterType: 'country',
        filterChannel: 'q',
        column: false,
      },
      {
        title: translate.purchase_order,
        width: 180,
        value: ({ order }) => (order || {}).referenceNo || '-',
        filter: 'orderId',
        filterType: 'order',
      },
      {
        title: translate.delivery_info,
        width: 400,
        render: ({ shippingProvider, address }) => {
          const { name = '', provider = '' } = shippingProvider || {};
          const { person = '', lines = [], city = '', country = '' } = address || {};
          return (
            <Box>
              <EllipsisTypography>
                {translate.delivery_method}: {(DeliveryMethodProvider[provider] || {}).label || name}
              </EllipsisTypography>
              {/PICKUP/.test(provider) ? (
                <EllipsisTypography>
                  {translate.pick_up_point}: {person} {lines.join(' ')} {city} {country}
                </EllipsisTypography>
              ) : (
                <EllipsisTypography>
                  {translate.shipping_address}: {person} {lines.join(' ')} {city} {country}
                </EllipsisTypography>
              )}
            </Box>
          );
        },
      },
      {
        title: translate.contact,
        render: ({ address }) => {
          const { tel, email = '' } = address || {};
          const { clear, region } = removePhoneNumberRegion(tel);

          return (
            <Box>
              <EllipsisTypography>
                {translate.customer_email}: {email || '-'}
              </EllipsisTypography>
              <Typography noWrap>
                {translate.mobile}: {region || '-'} {clear || '-'}
              </Typography>
            </Box>
          );
        },
      },
      {
        title: translate.tracking_number,
        fieldName: 'trackingNumber',
      },
      {
        title: translate.product_count,
        align: 'center',
        width: 180,
        render: ({ items }) => (
          <Typography>{(items || []).reduce((reducer, { quantity = 0 } = {}) => reducer + quantity, 0)}</Typography>
        ),
      },
      {
        title: translate.status,
        align: 'center',
        width: 150,
        fieldName: 'status',
        type: 'option',
        options: Object.keys(DeliveryNoteStatus).map((key) => ({
          label: <StatusBadge status={key} statusObject={DeliveryNoteStatus} />,
          value: key,
        })),
        filter: this.props.allowStatusFilter ? 'status' : undefined,
        sortBy: 'status.keyword',
      },
      {
        title: translate.other_actions,
        fieldName: 'action',
        width: 150,
        render: ({ id, shippingProvider, trackingNumber, status }) => {
          return (
            <Grid container wrap={'nowrap'}>
              <Grid item>
                <PrintOptionsButton id={id} partialHref={'print_delivery_notes'} />
              </Grid>
              {shippingProvider?.provider === 'SF_EXPRESS' && (
                <Grid item>
                  <SfPrintButton id={id} />
                </Grid>
              )}
              {/SHIPANY/.test(shippingProvider?.provider) && (
                <Grid item>
                  <ShipAnyButton id={id} />
                </Grid>
              )}
              {/FREIGHT_AMIGO_PICKUP/.test(shippingProvider?.provider) && (
                <Grid item>
                  <FreightAmigoPickupButton id={id} trackingNumber={trackingNumber} />
                </Grid>
              )}
            </Grid>
          );
        },
        noLink: true,
      },
    ],
    selectionMode: true,
    menus: [MenuPrintLabel, MenuProcess, MenuComplete, MenuPackingList],
    hasQSearch: true,
    qFields: [
      'referenceNo',
      'trackingNumber',
      'address.person',
      'address.tel',
      'address.email',
      'address.lines',
      'shippingProvider.name',
    ],
  };

  getData = ({ node } = {}) => {
    const { deliveryNotes } = node || {};
    return deliveryNotes;
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('companyId') };
  }
  renderWrapper(...args) {
    return this.renderContent(...args);
  }
}

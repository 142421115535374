import React from 'react';
import { Box, InputAdornment } from '@material-ui/core';
import { translate } from 'shared/translate';
import SelectField from 'components/FormPageMaker/Fields/SelectField';
import InputField from 'components/FormPageMaker/Fields/InputField';
import { Query } from '@apollo/client/react/components';
import { GET_MEMBER_TIER, GET_PRODUCTS } from './query';
import SelectComboProduct from '../../../components/SelectCombo/SelectComboProduct';
import SelectComboMemberTier from '../../../components/SelectCombo/SelectComboMemberTier';
import { parseConnection } from '../../../shared';
import LSkeleton from '../../../components/LSkeleton';
import TriggerFieldCollectionCodes from './TriggerFieldCollectionCodes';
import HashTagsArrayField from '../../../components/FormPageMaker/Fields/HashTagsArrayField';
import SelectComboShop, { SEARCH_QUERY } from '../../../components/SelectCombo/SelectComboShop';
import TriggerFieldTimeRange from './TriggerFieldTimeRange';
import SelectComboCountry from '../../../components/SelectCombo/SelectComboCountry';
import TriggerFieldWeekDay from './TriggerFieldWeekDay';

export default ({ trigger = {}, onChange, disabled, excludeModes = [] }) => {
  const { triggerType, triggerValueType, triggerValue, triggerId, triggerIds } = trigger || {};

  const extraInputs = (
    <>
      <Box flex={1} mr={2}>
        <SelectField
          value={triggerValueType}
          onChange={(e) => {
            trigger.triggerValueType = e.target.value;
            onChange(trigger);
          }}
          options={[
            { label: translate.achieve_amount, value: 'AMOUNT' },
            { label: translate.achieve_quantity, value: 'QUANTITY' },
            { label: translate.achieve_every_amount, value: 'EVERY_AMOUNT' },
            { label: translate.achieve_every_quantity, value: 'EVERY_QUANTITY' },
          ].filter((_) => _)}
        />
      </Box>
      <Box flex={1}>
        <InputField
          type={'number'}
          // eslint-disable-next-line
          InputProps={
            {
              AMOUNT: { startAdornment: <InputAdornment position={'start'}>$</InputAdornment> },
              EVERY_AMOUNT: { startAdornment: <InputAdornment position={'start'}>$</InputAdornment> },
              QUANTITY: { endAdornment: <InputAdornment position={'end'}>{translate.unit_item}</InputAdornment> },
              EVERY_QUANTITY: { endAdornment: <InputAdornment position={'end'}>{translate.unit_item}</InputAdornment> },
            }[triggerValueType]
          }
          value={triggerValue}
          required={true}
          onChange={(e) => {
            trigger.triggerValue = e.target.value;
            onChange(trigger);
          }}
        />
      </Box>
    </>
  );

  return (
    <Box display={'flex'} alignItems={'center'}>
      <Box flex={1} mr={triggerType !== 'ALL' ? 2 : 0}>
        <SelectField
          value={triggerType}
          required
          placeholder={translate.discount_condition}
          options={[
            {
              label: translate.no_condition,
              value: 'ALL',
            },
            {
              label: `${translate.create_order_at} (${translate.pos_only})`,
              value: 'TIME',
            },
            {
              label: `${translate.week_day_availability} (${translate.pos_only})`,
              value: 'WEEK_DAY',
            },
            {
              label: translate.designated_product,
              value: 'PRODUCT',
            },
            {
              label: translate.designated_product_hashtags,
              value: 'PRODUCT_TAG',
            },
            {
              label: translate.designated_collection,
              value: 'COLLECTION',
            },
            {
              label: translate.transaction_subtotal,
              value: 'ORDER',
            },
            {
              label: translate.checkout_tag,
              value: 'CHECKOUT_TAG',
            },
            {
              label: translate.member_tag,
              value: 'MEMBER_TAG',
            },
            {
              label: translate.designated_member_tier,
              value: 'MEMBER_TIER',
            },
            {
              label: translate.birth_month,
              value: 'BIRTH_MONTH',
            },
            {
              label: translate.exclude_designated_product,
              value: 'EXCLUDE_PRODUCT',
            },
            {
              label: translate.designated_shop,
              value: 'SHOP',
            },
            {
              label: translate.set_country_region,
              value: 'SHIPPING_COUNTRY',
            },
          ].map((opt) => ({
            ...opt,
            disabled: !!~excludeModes.indexOf(opt.value),
          }))}
          onChange={(e) => {
            trigger.triggerType = e.target.value;
            trigger.triggerId = undefined;
            trigger.triggerIds = undefined;
            trigger.triggerValueType = 'AMOUNT';
            if (trigger.triggerType === 'ALL') {
              trigger.triggerValue = 0;
            }
            onChange(trigger);
          }}
        />
      </Box>
      {!!~['PRODUCT', 'EXCLUDE_PRODUCT'].indexOf(triggerType) && (
        <>
          <Box flex={1} mr={2}>
            <Query
              query={GET_PRODUCTS}
              variables={{ id: localStorage.getItem('shopId'), skus: triggerIds }}
              skip={(triggerIds || []).length === 0}
              onCompleted={({ node } = {}) => {
                const products = parseConnection((node || {}).products).nodes;
                trigger.triggerIdName = products.map((product) => product.name).join('/');
                onChange(trigger);
              }}
            >
              {({ loading, data: { node } = {} }) => {
                const _products = parseConnection((node || {}).products).nodes;
                const products = _products.concat(
                  (triggerIds || [])
                    .filter((triggerId) => !_products.find((col) => col.id === triggerId || col.sku === triggerId))
                    .map((triggerId) => ({
                      sku: triggerId,
                    })),
                );
                return (
                  <SelectComboProduct
                    copyableType={'ShopProduct'}
                    idKey={'sku'}
                    multiple={true}
                    required={true}
                    disabled={disabled}
                    value={products}
                    onChange={(products) => {
                      trigger.triggerIds = products.map((product) => product.sku ?? product.id);
                      trigger.triggerIdName = products.map((product) => product.name).join('/');
                      onChange(trigger);
                    }}
                  />
                );
              }}
            </Query>
          </Box>
          {triggerType === 'PRODUCT' && extraInputs}
        </>
      )}
      {triggerType === 'COLLECTION' && (
        <>
          <Box flex={1} mr={2}>
            <TriggerFieldCollectionCodes disabled={disabled} trigger={trigger} onChange={onChange} />
          </Box>
          {extraInputs}
        </>
      )}
      {triggerType === 'ORDER' && extraInputs}
      {triggerType === 'TIME' && (
        <Box flex={1} mr={2}>
          <TriggerFieldTimeRange disabled={disabled} trigger={trigger} onChange={onChange} />
        </Box>
      )}
      {triggerType === 'WEEK_DAY' && (
        <Box flex={1} mr={2}>
          <TriggerFieldWeekDay disabled={disabled} trigger={trigger} onChange={onChange} />
        </Box>
      )}
      {triggerType === 'MEMBER_TIER' && (
        <Box flex={1} mr={2}>
          <Query
            query={GET_MEMBER_TIER}
            variables={{ id: triggerId }}
            skip={!triggerId}
            onCompleted={({ node } = {}) => {
              const { name } = node || {};
              trigger.triggerIdName = name;
              onChange(trigger);
            }}
          >
            {({ loading, data: { node } = {} }) => {
              const { name } = node || {};
              return (
                <SelectComboMemberTier
                  copyableType={'CompanyMemberTier'}
                  required={true}
                  disabled={disabled}
                  value={{ id: triggerId, name: loading ? translate.loading : name }}
                  onChange={({ id, name } = {}) => {
                    trigger.triggerId = id;
                    trigger.triggerIdName = name;
                    onChange(trigger);
                  }}
                />
              );
            }}
          </Query>
        </Box>
      )}
      {['MEMBER_TAG', 'CHECKOUT_TAG'].includes(triggerType) && (
        <Box flex={1} mr={2}>
          <HashTagsArrayField
            value={triggerIds || []}
            onChange={(v) => {
              trigger.triggerIds = (v || []).filter((_) => _);
              onChange(trigger);
            }}
            placeholder={translate.seo_keyword_description || `系統會自動在輸入內容前加上 '#' 符號，請直接輸入內容`}
            disabled={disabled}
          />
        </Box>
      )}
      {['PRODUCT_TAG'].includes(triggerType) && (
        <>
          <Box flex={1} mr={2}>
            <HashTagsArrayField
              value={triggerIds || []}
              onChange={(v) => {
                trigger.triggerIds = (v || []).filter((_) => _);
                onChange(trigger);
              }}
              placeholder={translate.seo_keyword_description || `系統會自動在輸入內容前加上 '#' 符號，請直接輸入內容`}
              disabled={disabled}
            />
          </Box>
          {extraInputs}
        </>
      )}
      {triggerType === 'SHOP' && (
        <Query
          query={SEARCH_QUERY}
          variables={{ id: localStorage.getItem('companyId') }}
          skip={(triggerIds || []).length === 0}
        >
          {({ loading, data: { node } = {} }) => {
            const shops = parseConnection((node || {}).shops).nodes;
            return loading ? (
              <LSkeleton height={30} />
            ) : (
              <Box flex={1}>
                <SelectComboShop
                  copyableType={'CompanyShop'}
                  multiple={true}
                  disabled={disabled}
                  value={shops.filter((shop) => !!triggerIds.includes(shop.id))}
                  onChange={(shops) => {
                    trigger.triggerIds = shops.map(({ id }) => id);
                    onChange(trigger);
                  }}
                />
              </Box>
            );
          }}
        </Query>
      )}
      {triggerType === 'SHIPPING_COUNTRY' && (
        <Box flex={1} mr={2}>
          <SelectComboCountry
            multiple
            value={triggerIds || []}
            onChange={(countries) => {
              trigger.triggerIds = countries;
              onChange(trigger);
            }}
          />
        </Box>
      )}
    </Box>
  );
};

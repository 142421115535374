import React from 'react';
import moment from 'moment';
import LSkeleton from '../../LSkeleton';
import { makeStyles } from '@material-ui/core';
import SelectField from './SelectField';
import _ from 'lodash';

const useStyle = makeStyles((theme) => ({
  fieldHandler: {
    padding: `${5}px ${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#c4c4c4',
    borderStyle: 'solid',
    '&:hover': {
      borderColor: '#000',
    },
  },
}));

const CustomTimeField2 = ({ loading, value, onChange, disabled, required, minuteStep = 1 }) => {
  const classes = useStyle();

  const options = _.uniqBy(
    (() => {
      const endThreshold = moment().endOf('day');
      const moments = [moment().startOf('day')];
      let previous = moments[moments.length - 1];

      while (endThreshold.isAfter(previous)) {
        moments.push(previous.clone().add(minuteStep, 'minute'));
        previous = moments[moments.length - 1];
      }
      return moments.map((m) => {
        return {
          label: m.format('A HH : mm'),
          value: m.format('HH:mm'),
        };
      });
    })(),
    'value',
  ).concat({
    label: 'End of the day',
    value: '24:00',
  });

  if (loading) return <LSkeleton height={30} />;
  return (
    <div className={classes.fieldHandler}>
      <SelectField
        required={required}
        variant={'standard'}
        InputProps={{
          disableUnderline: true,
          placeholder: '-- : --',
        }}
        value={value}
        onChange={(e) => onChange(e.target?.value)}
        disabled={disabled}
        options={options}
      />
    </div>
  );
};
export default CustomTimeField2;

import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import {
  CheckboxField,
  DateField,
  EmailField,
  ImageField,
  InputField,
  ListMultipleField,
  ListSingleField,
  MonthField,
  NumberField,
  RadioField,
  TextareaField,
  TimeField,
  YearField,
} from './inputFields';
import { Skeleton } from '@material-ui/lab';
import { ShopFormRecordStatus } from '../../shared/omniwe-types';
import StatusBadge from '../StatusBadge';
import { basicFieldNameMap } from '../../routes/item_CustomerFieldsSetting/BasicFieldsSetting';
import { convertMetaObject, JSONParseSafely } from '../../shared';
import CustomFieldWrapper from './inputFields/CustomFieldWrapper';
import SelectComboCountry from '../SelectCombo/SelectComboCountry';

const typeToComponent = {
  TEXT: InputField,
  TEXT_MULTIPLE: TextareaField,
  EMAIL: EmailField,
  NUMBER: NumberField,
  RADIO: RadioField,
  CHECKBOX: CheckboxField,
  IMAGE: ImageField,
  YEAR: YearField,
  MONTH: MonthField,
  DATE: DateField,
  TIME: TimeField,
  LIST_SINGLE: ListSingleField,
  LIST_MULTIPLE: ListMultipleField,
};

const CustomFormMetadataField = ({
  shouldNotRequired,
  loading,
  value: metadata,
  onChange,
  disabled,
  status,
  metadataFields,
}) => {
  return (
    <>
      {loading ? (
        <CustomFormSkeleton />
      ) : (
        <>
          {status && (
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <StatusBadge status={status} statusObject={ShopFormRecordStatus} />
              </Grid>
            </Grid>
          )}

          {metadataFields
            ?.filter((metadataField) => metadataField?.key !== 'primary')
            ?.map((field, i) => {
              const Component = typeToComponent[field?.type] || React.Fragment;

              if (/contactAddress/.test(field.name)) {
                const { metadata: _metadata, required, key } = field ?? {};
                const { remarks, length, placeholder } = convertMetaObject(_metadata);

                const address = JSONParseSafely(metadata[key])?.lines || [];
                const country = JSONParseSafely(metadata[key])?.country;
                return (
                  <Grid key={i} item xs={12} style={{ marginTop: i >= 1 ? '10px' : 0 }}>
                    <CustomFieldWrapper name={basicFieldNameMap[key]} remarks={remarks} required={required}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <InputField
                            value={address?.[0]}
                            onChange={(v) =>
                              onChange({
                                ...metadata,
                                [key]: JSON.stringify({ lines: [v, address[1], address[2]], country }),
                              })
                            }
                            required={required}
                            disabled={disabled}
                            placeholder={placeholder}
                            inputProps={{
                              maxLength: length,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <InputField
                            value={address?.[1]}
                            onChange={(v) =>
                              onChange({
                                ...metadata,
                                [key]: JSON.stringify({ lines: [address[0], v, address[2]], country }),
                              })
                            }
                            required={required}
                            disabled={disabled}
                            placeholder={placeholder}
                            inputProps={{
                              maxLength: length,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <InputField
                            value={address?.[2]}
                            onChange={(v) =>
                              onChange({
                                ...metadata,
                                [key]: JSON.stringify({ lines: [address[0], address[1], v], country }),
                              })
                            }
                            required={required}
                            disabled={disabled}
                            placeholder={placeholder}
                            inputProps={{
                              maxLength: length,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <SelectComboCountry
                            disabled={disabled}
                            value={country}
                            required={true}
                            onChange={(v) =>
                              onChange({
                                ...metadata,
                                [key]: JSON.stringify({ lines: address, country: v }),
                              })
                            }
                          />
                        </Grid>
                      </Grid>
                    </CustomFieldWrapper>
                  </Grid>
                );
              }
              return (
                <Grid key={i} item xs={12} style={{ marginTop: i >= 1 ? '10px' : 0 }}>
                  <Component
                    field={{
                      ...field,
                      name: /^@/.test(field.name) ? basicFieldNameMap[field?.key] : field?.name,
                      required: shouldNotRequired ? false : field?.required,
                    }}
                    value={metadata[field?.key]}
                    onChange={(v) => onChange({ ...metadata, [field?.key]: v })}
                    disabled={disabled}
                  />
                </Grid>
              );
            })}
        </>
      )}
    </>
  );
};

export default CustomFormMetadataField;

const useStyles = makeStyles(() => ({
  inputSkeleton: {
    height: 30,
    borderRadius: 4,
    marginTop: 5,
  },
}));

export const CustomFormSkeleton = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} justifyContent="flex-end">
      <Typography>
        <Skeleton width={10 + Math.random() * 200} height={40} />
      </Typography>
      {[...Array(10)].map((_, index) => (
        <Grid item xs={12} key={index}>
          <Typography>
            <Skeleton width={50 + Math.random() * 200} />
          </Typography>
          <Skeleton
            height={index === 1 || index === 2 ? 100 : undefined}
            variant={'rect'}
            className={classes.inputSkeleton}
          />
        </Grid>
      ))}
      <Grid item xs={12} md={3}>
        <Skeleton
          height={35}
          variant={'rect'}
          style={{
            borderRadius: 4,
          }}
        />
      </Grid>
    </Grid>
  );
};

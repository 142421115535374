import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export default ({
  size,
  controlled,
  loading,
  text,
  textVariant = 'h6',
  style = {},
  checked: _checked,
  onClick = (_) => _,
  onChange = (_) => _,
  disabled = false,
  label,
  ...props
}) => {
  const [checked, setChecked] = useState(!!_checked);

  useEffect(() => {
    if (!!checked !== !!_checked)
      onChange({
        target: {
          checked,
        },
      });
  }, [checked]);
  useEffect(() => {
    if (!!checked !== !!_checked) setChecked(!!_checked);
  }, [_checked]);

  if (loading)
    return (
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item>
          <Skeleton variant={'rect'} width={16} height={16} />
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <Typography variant={textVariant}>
                {!text && loading ? <Skeleton width={100} height={30} /> : text}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  return (
    <FormControlLabel
      style={{
        margin: 'auto 0',
        cursor: disabled ? 'not-allowed' : undefined,
        width: 'fit-content',
        ...style,
      }}
      disabled={disabled}
      control={
        <Checkbox
          size={size}
          disabled={disabled}
          edge={'start'}
          checked={controlled ? _checked : checked}
          onChange={(e) => {
            if (controlled) onChange(e);
            else setChecked(e.target.checked);
          }}
          onClick={(e) => {
            e.stopPropagation();
            onClick(e);
          }}
          {...props}
          color={'primary'}
        />
      }
      label={
        label ||
        (!!text && (
          <Typography style={{ whiteSpace: 'pre', marginLeft: -8 }} variant={textVariant}>
            {text}
          </Typography>
        ))
      }
    />
  );
};

import React, { useEffect, useState } from 'react';
import { infinityFetch, JSONParseSafely } from '../../shared';
import toast from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import ActivityIndicator from '../../components/ActivityIndicator';
import { GET_QUERY } from './query';
import { translate } from '../../shared/translate';
import Code54X40 from '../../components/ProductBarcodes/Code54X40';

const PrintProductBarcodes = () => {
  const { type = '54X40', quantity = 1 } = JSON.parse(
    '{"' + window.location.search.substring(1).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}',
  );
  const [nodes, setNodes] = useState([]);

  useEffect(async () => {
    const filter = JSONParseSafely(localStorage.getItem('print:barcodeItems:filter'), null);
    if (filter)
      try {
        const res = await infinityFetch({
          query: GET_QUERY,
          variables: {
            id: localStorage.getItem('shopId'),
            filter,
            take: 99999,
          },
          getConnection: (data) => data?.node?.products,
          onProgress: (downloaded, total) => {
            if (total > downloaded) ActivityIndicator.show(`${translate.loading} ${downloaded} / ${total}`);
            else ActivityIndicator.show(`${translate.loading}`);
          },
        });

        const result = res?.nodes
          ?.map((node) => {
            return Array.from({ length: +(quantity ?? 1) }, () => node);
          })
          .flat();
        setNodes(result);
      } catch (e) {
        toast.error(errorParser(e));
      } finally {
        ActivityIndicator.hide();
      }
  }, []);

  return (
    <>
      <style>{`
      .print-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
      }
      @media print {
        @page {
          margin: 0;
        }
        body {
          margin: 0;
        }
        .print-container {
          flex-direction:column;
        }
      }
  `}</style>
      {/* <Container style={{ maxWidth: 1200 }}> */}
      <div className={'print-container'}>
        {nodes?.map((product, i) =>
          product?.variations?.map((variation, j, arr) => {
            return (
              <Code54X40
                key={`${i}-${j}`}
                name={product?.name}
                unitPrice={variation?.unitPrice}
                sku={variation?.sku}
                barcode={(() => {
                  return variation?.sku;
                })()}
              />
            );
          }),
        )}
      </div>
    </>
  );
};

export default PrintProductBarcodes;

import FormPageMaker from '../../components/FormPageMaker';
import { translate } from '../../shared/translate';
import { GET_QUERY, UPDATE_QUERY } from './query';
import { client } from '../../shared/apollo';
import { RoleOptions } from './create';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: UPDATE_QUERY,
    },
    tabs: [
      {
        name: translate.login,
        cards: [
          {
            fields: [
              {
                label: translate.icon,
                type: 'image',
                name: 'picture',
              },
              {
                md: 6,
                label: translate.name,
                type: 'text',
                name: 'name',
                required: true,
              },
              {
                md: 4,
                label: translate.role,
                type: 'select',
                name: 'permissions',
                multiple: true,
                required: true,
                options: RoleOptions,
              },
              {
                md: 6,
                block: true,
                label: translate.email,
                type: 'email',
                name: 'email',
                required: true,
                disabled: true,
              },
              {
                md: 6,
                block: true,
                label: translate.password,
                type: 'password',
                name: 'password',
                inputProps: {
                  pattern: '(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}',
                },
                placeholder: '***********',
                text: translate.password_remark,
              },
            ],
          },
        ],
      },
    ],
  };

  getInitialData({ node }) {
    const { id, name, picture, email, hashtags, permissions } = node || {};
    return {
      id,
      name,
      picture,
      hashtags,
      email,
      permissions: permissions?.map((p) => p?.value)?.flat() ?? [],
    };
  }
  submit = async (values) => {
    const {
      gql: { submit },
    } = this.state;
    await client.mutate({
      mutation: submit,
      variables: {
        id: values.id,
        companyId: localStorage.getItem('companyId'),
        input: {
          picture: values.picture || null,
          name: values.name,
          permissions: [{ key: localStorage.getItem('companyId') + '/*', value: values?.permissions ?? [] }],
          // isEmailVerified: values.isEmailVerified,
          // isPhoneNumberVerified: values.isPhoneNumberVerified,
        },
        credential: {
          email: values.email,
          // mobileV2: values.phoneNumber,
          password: values.password,
        },
      },
    });
    return true;
  };
}

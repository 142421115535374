import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import CustomTimeField from '../../../components/FormPageMaker/Fields/CustomTimeField';
import SelectComboValue from '../../../components/SelectCombo/SelectComboValue';

const TriggerFieldWeekDay = ({ disabled, trigger = {}, onChange = (_) => _ }) => {
  const { triggerIds = [] } = trigger ?? {};

  return (
    <SelectComboValue
      disabled={disabled}
      multiple={true}
      options={[
        { label: translate.sunday, value: '0' },
        { label: translate.monday, value: '1' },
        { label: translate.tuesday, value: '2' },
        { label: translate.wednesday, value: '3' },
        { label: translate.thursday, value: '4' },
        { label: translate.friday, value: '5' },
        { label: translate.saturday, value: '6' },
      ]}
      value={triggerIds || []}
      onChange={(v) => {
        trigger.triggerIds = v;
        onChange(trigger);
      }}
    />
  );
};

export default TriggerFieldWeekDay;

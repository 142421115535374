import React, { useState } from 'react';
import { translate } from '../../shared/translate';
import { Box, Grid } from '@material-ui/core';
import StyledConfigList from '../../components/StyledConfigList';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import CheckboxField from '../../components/FormPageMaker/Fields/CheckboxField';
import { convertMetaArray, convertMetaObject } from '../../shared';
import SelectField from '../../components/FormPageMaker/Fields/SelectField';

export const basicFieldNameMap = {
  name: translate.customer_fullname,
  family_name: translate.family_name,
  given_name: translate.given_name,
  birthMonth: translate.birthMonth,
  birthYear: translate.birthYear,
  referCode: translate.invitation_code || '推薦碼（如有）',
  contactAddress: translate.contact_address || '聯絡地址',
};
export const basicFields = [
  {
    key: 'primary',
    name: '@primary',
    type: 'TEXT',
    required: true,
    values: [],
    metadata: [{ key: 'primary', value: 'EMAIL' }],
  },
  {
    key: 'name',
    name: '@name',
    type: 'TEXT',
    required: false,
    values: [],
    metadata: [
      { key: 'minLength', value: '0' },
      { key: 'maxLength', value: '100' },
    ],
  },
  {
    key: 'family_name',
    name: '@family_name',
    type: 'TEXT',
    required: false,
    values: [],
    metadata: [
      { key: 'minLength', value: '0' },
      { key: 'maxLength', value: '100' },
    ],
  },
  {
    key: 'given_name',
    name: '@given_name',
    type: 'TEXT',
    required: false,
    values: [],
    metadata: [
      { key: 'minLength', value: '0' },
      { key: 'maxLength', value: '100' },
    ],
  },
  {
    key: 'contactAddress',
    name: '@contactAddress',
    type: 'TEXT',
    required: false,
    values: [],
  },
  {
    key: 'birthMonth',
    name: '@birthMonth',
    type: 'MONTH',
    required: false,
    values: [],
  },
  {
    key: 'birthYear',
    name: '@birthYear',
    type: 'YEAR',
    required: false,
    values: [],
  },
  {
    key: 'referCode',
    name: '@referCode',
    type: 'TEXT',
    required: false,
    values: [],
  },
];

const BasicFieldsSetting = ({ value, loading, disabled, onChange = (_) => {} }) => {
  const [primaryValue, setPrimaryValue] = useState(convertMetaObject(value?.primary?.metadata).primary || 'EMAIL');

  return (
    <StyledConfigList spacing={2}>
      {basicFields.map((_bf, i) => {
        const bf = value[_bf.key];
        const visible = bf?.visible;
        const required = bf?.required;

        if (bf.key === 'primary')
          return (
            <Grid key={`primary-${i}`} item>
              {/* TODO: 待handle收OTP時，分單分錢，才使用
              <Box mb={primaryValue !== 'EMAIL' ? 0.5 : 0}> */}
              <Grid container alignItems={'center'}>
                <Grid item xs>
                  <SelectField
                    disabled={true}
                    value={primaryValue}
                    options={[
                      { label: translate._email_only || '僅使用電郵', value: 'EMAIL' },
                      { label: translate.mobile_only || '僅使用手機號碼', value: 'MOBILE' },
                      {
                        label: translate.email_or_phone || '電郵或手機號碼',
                        value: 'EMAIL_MOBILE',
                      },
                    ]}
                    onChange={(e) => {
                      setPrimaryValue(e.target.value);
                      onChange({
                        ...value,
                        [bf.key]: {
                          ...value[bf.key],
                          metadata: convertMetaArray({
                            ...convertMetaObject(value[bf.key]?.metadata),
                            primary: e.target.value,
                          }),
                        },
                      });
                    }}
                    InputProps={{
                      style: {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  style={{
                    background: 'white',
                    border: '1px solid #c9c9c9',
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    height: 32,
                  }}
                >
                  <Box px={2}>
                    <CheckboxField
                      style={{ marginTop: -4 }}
                      text={translate.required_s}
                      disabled={true}
                      controlled={true}
                      checked={true}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  style={{
                    background: 'white',
                    border: '1px solid #c9c9c9',
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    height: 32,
                  }}
                >
                  <Box px={2}>
                    {(() => {
                      return (
                        <CheckboxField
                          style={{ marginTop: -4 }}
                          text={translate.visible_at_registration || '註冊時填寫'}
                          disabled={true}
                          controlled={true}
                          checked={true}
                        />
                      );
                    })()}
                  </Box>
                </Grid>
                <Grid
                  item
                  style={{
                    background: 'white',
                    border: '1px solid #c9c9c9',
                    borderLeftWidth: 0,
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                    height: 32,
                  }}
                >
                  <Box px={2}>
                    <CheckboxField
                      style={{ marginTop: -4 }}
                      text={translate.visible}
                      disabled={true}
                      controlled={true}
                      checked={true}
                    />
                  </Box>
                </Grid>
              </Grid>
              {/* </Box>
              TODO: 待handle收OTP時，分單分錢，才顯示hint // hint: 若選擇手機號碼進行註冊，您需要擁有 xxxx 帳戶以發送
              OTP 驗證碼 // 屆時可能再作修改：加聯絡我們etc
              {primaryValue !== 'EMAIL' && (
                <Typography variant={'body2'} color={textColor} style={{ paddingLeft: 15 }}>
                  {translate.phone_hint}
                </Typography>
              )} */}
            </Grid>
          );

        return (
          <Grid key={i} item>
            <Grid container alignItems={'center'}>
              <Grid item xs>
                <InputField
                  value={/^@/.test(bf.name) ? basicFieldNameMap[bf.key] : bf.name}
                  disabled
                  InputProps={{
                    style: {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                style={{
                  background: 'white',
                  border: '1px solid #c9c9c9',
                  borderLeftWidth: 0,
                  borderRightWidth: 0,
                  height: 32,
                }}
              >
                <Box px={2}>
                  <CheckboxField
                    style={{ marginTop: -4 }}
                    text={translate.required_s}
                    disabled={disabled}
                    controlled={true}
                    checked={value[bf.key]?.required}
                    onChange={() => {
                      onChange({
                        ...value,
                        [bf.key]: {
                          ...value[bf.key],
                          required: !required,
                        },
                      });
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                style={{
                  background: 'white',
                  border: '1px solid #c9c9c9',
                  borderLeftWidth: 0,
                  borderRightWidth: 0,
                  height: 32,
                }}
              >
                <Box px={2}>
                  {(() => {
                    const checked = convertMetaObject(value[bf.key]?.metadata).visibleAtRegistration;
                    return (
                      <CheckboxField
                        style={{ marginTop: -4 }}
                        text={translate.visible_at_registration || '註冊時填寫'}
                        disabled={disabled}
                        controlled={true}
                        checked={checked}
                        onChange={() => {
                          onChange({
                            ...value,
                            [bf.key]: {
                              ...value[bf.key],
                              metadata: convertMetaArray({
                                ...convertMetaObject(value[bf.key]?.metadata),
                                visibleAtRegistration: !checked,
                              }),
                            },
                          });
                        }}
                      />
                    );
                  })()}
                </Box>
              </Grid>
              <Grid
                item
                style={{
                  background: 'white',
                  border: '1px solid #c9c9c9',
                  borderLeftWidth: 0,
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  height: 32,
                }}
              >
                <Box px={2}>
                  <CheckboxField
                    style={{ marginTop: -4 }}
                    text={translate.visible}
                    disabled={disabled}
                    controlled={true}
                    checked={value[bf.key]?.visible}
                    onChange={() => {
                      onChange({
                        ...value,
                        [bf.key]: {
                          ...value[bf.key],
                          visible: !visible,
                        },
                      });
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </StyledConfigList>
  );
};

export default BasicFieldsSetting;

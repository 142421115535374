import { translate as t } from '../../shared/translate';
import moment from 'moment';

export default ({ triggers = [], actions = [] }) => {
  const triggerRemark = getTriggerRemark(triggers[0]),
    actionRemark = getActionRemark(actions[0]);

  if (triggerRemark) return `${triggerRemark || '-'} => ${actionRemark || '-'}`;
  return actionRemark || '-';
};

function getTriggerRemark({ triggerType, triggerIds, triggerIdName = '?', triggerValueType, triggerValue } = {}) {
  let remark = '';

  if (triggerType !== 'ALL') {
    switch (triggerType) {
      case 'ALL':
        break;
      case 'PRODUCT':
        remark = `${t.designated_product}:${triggerIdName}`;
        break;
      case 'COLLECTION':
        remark = `${t.designated_collection}:${triggerIdName}`;
        break;
      case 'ORDER':
        remark = `${t.discount_condition_subtotal}`;
        break;
      case 'MEMBER_TIER':
        remark = `${t.designated_member_tier}:${triggerIdName}`;
        break;
      case 'PRODUCT_TAG':
        remark = `${t.designated_product_hashtags}:${triggerIds?.join(',')}`;
        break;
      case 'TIME':
        remark = `${t.create_order_at}${t.available_date_from}${
          triggerIds?.[0] ? moment(triggerIds?.[0]).format('HH:mm') : '--'
        }${t.available_date_to}${triggerIds?.[1] ? moment(triggerIds?.[1]).format('HH:mm') : '--'}`;
        break;
      case 'WEEK_DAY':
        remark = `${triggerIds
          ?.map(
            (id) =>
              ({
                0: t.sunday,
                1: t.monday,
                2: t.tuesday,
                3: t.wednesday,
                4: t.thursday,
                5: t.friday,
                6: t.saturday,
              }[id]),
          )
          ?.join(',')}`;
      default:
        break;
    }

    if (!['MEMBER_TIER', 'WEEK_DAY', 'TIME'].includes(triggerType)) {
      switch (triggerValueType) {
        case 'EVERY_AMOUNT':
          remark += t.achieve_every;
          remark += `$${triggerValue}`;
          break;
        case 'AMOUNT':
          remark += t.achieve;
          remark += `$${triggerValue}`;
          break;
        case 'EVERY_QUANTITY':
          remark += t.achieve_every;
          remark += `${triggerValue}${t.unit_item}`;
          break;
        case 'QUANTITY':
          remark += t.achieve;
          remark += `${triggerValue}${t.unit_item}`;
          break;
        default:
          break;
      }
    }
  }

  return remark;
}
function getActionRemark({ actionType, actionId, actionIds, actionIdName = '?', actionValueType, actionValue } = {}) {
  let remark = '';
  switch (actionType) {
    case 'COLLECTION':
      remark += `${t.designated_collection}:${actionIdName}`;
      break;
    case 'PRODUCT':
      remark += `${t.designated_product}:${actionIdName}`;
      break;
    case 'ORDER':
      remark += t.discount_for_order;
      break;
    case 'FREE_SHIP':
      remark += `${t.discount_for_free_ship}:${actionIdName}`;
      break;
    case 'MEMBER_POINTS':
      remark += `${t.member_points}`;
      break;
    case 'PRODUCT_TAG':
      remark += `${t.designated_product_hashtags}:${actionIds?.join(',')}`;
      break;
    default:
      break;
  }
  if (!!~['COLLECTION', 'PRODUCT', 'ORDER', 'PRODUCT_TAG'].indexOf(actionType)) {
    switch (actionValueType) {
      case 'PERCENTAGE':
        remark += `${t.discounts}${actionValue}%OFF`;
        break;
      case 'AMOUNT':
        remark += `${t.discount_fixed_amount}${actionValue}`;
        break;
      case 'GIFT':
        remark += `${t.discount_gift}${actionValue}${t.unit_item}`;
        break;
      default:
        break;
    }
  } else if (actionType === 'MEMBER_POINTS') {
    switch (actionValueType) {
      case 'PERCENTAGE':
        remark += `${actionValue}%`;
        break;
      case 'AMOUNT':
        remark += `+${actionValue}`;
        break;
      default:
        break;
    }
  }

  return remark;
}

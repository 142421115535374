import React, { useState } from 'react';
import FormRow from '../../FormRow';
import PasswordField from '../Fields/PasswordField';
import ImageField from '../Fields/ImageField';
import ImagesField from '../Fields/ImagesField';
import DateRangeField from '../Fields/DateRangeField';
import { Card, CardHeader, CardContent, Typography, Grid } from '@material-ui/core';
import InputField from '../Fields/InputField';
import TextareaField from '../Fields/TextareaField';
import HTMLField from '../Fields/HTMLField';
import HashTagsField from '../Fields/HashTagsField';
import CountriesField from '../Fields/CountriesField';
import CheckboxField from '../Fields/CheckboxField';
import SwitchField from '../Fields/SwitchField';
import AddressLinesField from '../Fields/AddressLinesField';
import CountryField from '../Fields/CountryField';
import HashTagsArrayField from '../Fields/HashTagsArrayField';
import DateTimeField from '../Fields/DateTimeField';
import MediaField from '../Fields/MediaField';
import MediasField from '../Fields/MediasField';
import ColorField from '../Fields/ColorField';
import LSkeleton from '../../LSkeleton';
import MobileField from '../Fields/MobileField';
import PasswordUpdateField from '../Fields/PasswordUpdateField';
import { makerParseFunctionValue } from '../../../shared';
import PeriodField from '../Fields/PeriodField';
import SelectCombo from '../../SelectCombo';
import DurationField from '../Fields/DurationField';
import AddressField from '../Fields/AddressField';
import { translate } from '../../../shared/translate';
import EllipsisTypography from '../../EllipsisTypography';
import DistrictField from '../Fields/DistrictField';
import DaysOfWeekField from '../Fields/DaysOfWeekField';

export default ({ extra, name, fields, actions, style, comingSoon = false, ...props }) => {
  const { values, setFieldValue, loading, isSubmitting } = actions;
  const [hover, setHover] = useState();

  return (
    <Card
      square
      elevation={0}
      style={style}
      {...props}
      onMouseOver={!!comingSoon ? () => setHover(true) : undefined}
      onMouseOut={!!comingSoon ? () => setHover(false) : undefined}
    >
      {!!name && <CardHeader title={name} action={extra} />}
      <CardContent>
        <Grid container spacing={3}>
          {fields.map((_field, i) => {
            if (
              typeof _field === 'function' &&
              (!!_field.prototype.isReactComponent || String(_field).includes('createElement'))
            ) {
              const Field = _field;
              return <Field key={i} {...actions} />;
            }
            const field = makerParseFunctionValue(_field, actions, ['afterChanged']);

            const {
                block,
                md = 12,
                xs = 12,
                sm,
                lg,
                xl,
                label,
                description,
                remarks,
                render,
                name,
                type,
                options,
                text,
                bottomText,
                required,
                disabled,
                numberOnly,
                display,
                afterChanged,
                direction,
                dense,
                id,
                formRowTheme,
                tooltip,
                formRowStyle,
                ...props
              } = field,
              formRowProps = {
                key: i,
                id,
                title: label,
                remarks,
                required,
                md,
                xs,
                sm,
                lg,
                xl,
                block,
                direction,
                dense,
                formRowTheme,
                style: formRowStyle,
                tooltip,
              };

            const _description = description || text;
            if (display === false) return null;

            if (render) return <FormRow {...formRowProps}>{render}</FormRow>;
            let _value = '';
            try {
              _value = values[name];
              if (!_value) {
                // eslint-disable-next-line
                eval(`_value = values.${name}`);
              }
            } catch (e) {}

            const onChange = (v, key = name) => {
              setFieldValue(key, v);
              if (afterChanged)
                setTimeout(() => {
                  afterChanged(v, actions);
                }, 0);
            };

            switch (type) {
              case 'span':
                const content = text || _value;
                return (
                  <FormRow className={'form-row-placeholder'} {...formRowProps}>
                    <Typography>{loading && !!content ? <LSkeleton /> : content}</Typography>
                  </FormRow>
                );
              case 'time':
              case 'email':
              case 'text':
              case 'tel':
              case 'number':
                return (
                  <FormRow {...formRowProps}>
                    <InputField
                      loading={loading}
                      type={type}
                      name={name}
                      value={_value}
                      options={options}
                      onChange={
                        !!numberOnly
                          ? (e) => {
                              onChange(e.target.value.replace(/\D/g, ''));
                            }
                          : (e) => {
                              onChange(e.target.value);
                            }
                      }
                      required={required}
                      disabled={isSubmitting || disabled}
                      {...props}
                    />
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
              case 'color':
                return (
                  <FormRow {...formRowProps}>
                    <ColorField
                      loading={loading}
                      name={name}
                      value={_value}
                      options={options}
                      onChange={(v) => onChange(v)}
                      required={required}
                      disabled={isSubmitting || disabled}
                      {...props}
                    />
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
              case 'duration':
                return (
                  <FormRow {...formRowProps}>
                    <DurationField
                      loading={loading}
                      name={name}
                      value={_value}
                      onChange={(v) => onChange(v)}
                      required={required}
                      disabled={isSubmitting || disabled}
                    />
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
              case 'period':
                return (
                  <FormRow {...formRowProps}>
                    <PeriodField
                      loading={loading}
                      name={name}
                      value={_value}
                      onChange={(v) => onChange(v)}
                      required={required}
                      disabled={isSubmitting || disabled}
                      {...props}
                    />
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
              case 'date':
              case 'datetime-local':
                return (
                  <FormRow {...formRowProps}>
                    <DateTimeField
                      loading={loading}
                      type={type}
                      name={name}
                      value={_value}
                      options={options}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      required={required}
                      disabled={isSubmitting || disabled}
                      {...props}
                    />
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
              case 'hashTagsArray':
                return (
                  <FormRow {...formRowProps}>
                    <HashTagsArrayField
                      loading={loading}
                      value={_value}
                      onChange={(v) => onChange(v)}
                      required={required}
                      disabled={isSubmitting || disabled}
                      {...props}
                    />
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
              case 'hashtags':
                return (
                  <FormRow {...formRowProps}>
                    <HashTagsField
                      loading={loading}
                      value={_value}
                      onChange={(v) => onChange(v)}
                      required={required}
                      disabled={isSubmitting || disabled}
                      {...props}
                    />
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
              case 'mobile':
                return (
                  <FormRow {...formRowProps}>
                    <MobileField
                      loading={loading}
                      value={_value}
                      onChange={(v) => onChange(v)}
                      required={required}
                      disabled={isSubmitting || disabled}
                      {...props}
                    />
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
              case 'textarea':
                return (
                  <FormRow {...formRowProps}>
                    <TextareaField
                      loading={loading}
                      name={name}
                      value={_value}
                      onChange={(v) => onChange(v)}
                      required={required}
                      disabled={isSubmitting || disabled}
                      {...props}
                    />
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
              case 'address':
                return (
                  <FormRow {...formRowProps}>
                    <AddressField
                      loading={loading}
                      value={_value}
                      onChange={(value) => onChange(value)}
                      required={required}
                      disabled={isSubmitting || disabled}
                      {...props}
                    />
                  </FormRow>
                );
              case 'lines':
                return (
                  <FormRow {...formRowProps}>
                    <AddressLinesField
                      loading={loading}
                      value={_value}
                      onChange={(value) => onChange(value)}
                      required={required}
                      disabled={isSubmitting || disabled}
                      {...props}
                    />
                  </FormRow>
                );
              case 'select':
                return (
                  <FormRow {...formRowProps}>
                    {loading ? (
                      <LSkeleton height={30} />
                    ) : (
                      <SelectCombo
                        loading={loading}
                        name={name}
                        value={
                          props?.multiple
                            ? options.filter((opt) => _value?.includes(opt?.value))
                            : options.find((opt) => opt.value === _value) || undefined
                        }
                        required={required}
                        onChange={(v) => {
                          if (props?.multiple) {
                            onChange(v?.map((opt) => opt?.value)?.filter(Boolean) ?? []);
                          } else onChange(v?.value);
                        }}
                        disabled={isSubmitting || disabled}
                        options={options}
                        {...props}
                      />
                    )}
                    {/*<SelectField*/}
                    {/*  loading={loading}*/}
                    {/*  name={name}*/}
                    {/*  value={_value}*/}
                    {/*  required={required}*/}
                    {/*  onChange={handleChange}*/}
                    {/*  disabled={isSubmitting || disabled}*/}
                    {/*  options={options}*/}
                    {/*  {...props}*/}
                    {/*/>*/}
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
              case 'switch':
                return (
                  <FormRow {...formRowProps}>
                    <SwitchField
                      {...props}
                      loading={loading}
                      onChange={(e) => onChange(e.target.checked)}
                      name={name}
                      disabled={isSubmitting || disabled}
                      required={required}
                      checked={props.checked !== undefined ? !!props.checked : !!_value}
                    />
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
              case 'checkbox':
                return (
                  <FormRow {...formRowProps} title={''}>
                    <CheckboxField
                      {...props}
                      loading={loading}
                      onChange={(e) => onChange(e.target.checked)}
                      name={name}
                      disabled={isSubmitting || disabled}
                      required={required}
                      checked={props.checked !== undefined ? !!props.checked : !!_value}
                      text={label}
                    />
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
              case 'html':
                return (
                  <FormRow {...formRowProps}>
                    <HTMLField
                      loading={loading}
                      value={_value}
                      required={required}
                      disabled={isSubmitting || disabled}
                      onChange={(c) => onChange(c)}
                      {...props}
                    />
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
              case 'district':
                return (
                  <FormRow {...formRowProps}>
                    <DistrictField
                      {...props}
                      loading={loading}
                      disabled={isSubmitting || disabled}
                      value={_value}
                      required={required}
                      onChange={(v) => onChange(v)}
                    />
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
              case 'daysOfWeek':
                return (
                  <FormRow {...formRowProps}>
                    <DaysOfWeekField
                      {...props}
                      loading={loading}
                      disabled={isSubmitting || disabled}
                      value={_value}
                      required={required}
                      onChange={(v) => onChange(v)}
                    />
                  </FormRow>
                );
              case 'country':
                return (
                  <FormRow {...formRowProps}>
                    <CountryField
                      {...props}
                      loading={loading}
                      disabled={isSubmitting || disabled}
                      value={_value}
                      required={required}
                      onChange={(v) => onChange(v)}
                    />
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
              case 'countries':
                return (
                  <FormRow {...formRowProps}>
                    <CountriesField
                      name={name}
                      onChange={(v) => onChange(v)}
                      isSubmitting={isSubmitting}
                      disabled={disabled}
                      value={_value || []}
                      required={required}
                      {...props}
                    />
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
              case 'password':
                return (
                  <FormRow {...formRowProps}>
                    <PasswordField
                      {...props}
                      loading={loading}
                      name={name}
                      disabled={isSubmitting || disabled}
                      value={_value}
                      required={required}
                      onChange={(e) => onChange(e.target.value)}
                    />
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
              case 'password_update':
                return (
                  <FormRow {...formRowProps}>
                    <PasswordUpdateField
                      {...props}
                      loading={loading}
                      name={name}
                      disabled={isSubmitting || disabled}
                      value={_value}
                      required={required}
                      onChange={(v) => onChange(v)}
                    />
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
              case 'media':
                return (
                  <FormRow {...formRowProps}>
                    {!!_description && (
                      <EllipsisTypography variant={'body2'} paragraph={true}>
                        {_description}
                      </EllipsisTypography>
                    )}
                    <MediaField
                      {...props}
                      loading={loading}
                      name={name}
                      disabled={isSubmitting || disabled}
                      value={_value}
                      required={required}
                      onChange={(v) => onChange(v)}
                    />
                    {!!bottomText && (
                      <Typography variant={'body2'} paragraph={true} style={{ margin: '10px 0 0 0' }}>
                        {bottomText}
                      </Typography>
                    )}
                  </FormRow>
                );
              case 'medias':
                return (
                  <FormRow {...formRowProps}>
                    <MediasField
                      {...props}
                      loading={loading}
                      name={name}
                      disabled={isSubmitting || disabled}
                      value={_value}
                      required={required}
                      onChange={(v) => onChange(v)}
                    />
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
              case 'image':
                return (
                  <FormRow {...formRowProps}>
                    {!!_description && (
                      <EllipsisTypography variant={'body2'} paragraph={true}>
                        {_description}
                      </EllipsisTypography>
                    )}
                    <ImageField
                      {...props}
                      loading={loading}
                      name={name}
                      disabled={isSubmitting || disabled}
                      value={_value}
                      required={required}
                      onChange={(v) => onChange(v)}
                    />
                  </FormRow>
                );
              case 'images':
                return (
                  <FormRow {...formRowProps}>
                    <ImagesField
                      {...props}
                      loading={loading}
                      name={name}
                      disabled={isSubmitting || disabled}
                      images={_value}
                      required={required}
                      onChange={(v) => onChange(v)}
                    />
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
              case 'date_range':
                const { nameAt, nameThru } = field || {},
                  valueAt = values[nameAt],
                  valueThru = values[nameThru];
                return (
                  <FormRow {...formRowProps}>
                    <DateRangeField
                      {...props}
                      loading={loading}
                      fromValue={valueAt}
                      toValue={valueThru}
                      onFromValueChange={(v) => onChange(v, nameAt)}
                      onToValueChange={(v) => onChange(v, nameThru)}
                      required={required}
                      disabled={isSubmitting || disabled}
                    />
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
              default:
                return (
                  <FormRow {...formRowProps}>
                    {!!_description && <Typography variant={'body2'}>{_description}</Typography>}
                  </FormRow>
                );
            }
          })}
        </Grid>
      </CardContent>
      {!!comingSoon && (
        <div
          style={{
            position: 'absolute',
            zIndex: 1,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            transition: 'all 0.25s',
            opacity: hover ? 1 : 0,
            backgroundColor: 'rgba(255,255,255,0.85)',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant={'h4'}>{translate.coming_soon}</Typography>
        </div>
      )}
    </Card>
  );
};

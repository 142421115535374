import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { GET_QUERY, SUBMIT_QUERY } from './query';
import { translate } from '../../shared/translate';
import { TableStatus } from '../../shared/omniwe-types';
import StatusBadge from '../../components/StatusBadge';
import { client } from '../../shared/apollo';
import { convertMetaArray, convertMetaObject } from '../../shared';
import { Grid, InputAdornment, Typography } from '@material-ui/core';
import CheckboxField from '../../components/FormPageMaker/Fields/CheckboxField';
import CustomTimeField2 from '../../components/FormPageMaker/Fields/CustomTimeField2';

const { REACT_APP_FNB_QR_URL } = process.env || {};

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: SUBMIT_QUERY,
    },
    tabs: [
      {
        name: translate.table_information || '餐桌資料',
        cards: [
          {
            fields: [
              {
                label: translate.name,
                type: 'span',
                name: 'name',
                md: 6,
              },
              {
                label: translate.status,
                render: ({ loading, values: { status } = {} } = {}) => (
                  <StatusBadge loading={loading} status={status} statusObject={TableStatus} />
                ),
                md: 6,
              },
              {
                label: translate.number_of_seats_at_table || '餐桌可容納人數',
                type: 'number',
                name: 'metadata.maximumAvailablePeople',
                md: 6,
                inputProps: {
                  step: 1,
                  min: 1,
                },
              },
              {
                label: translate.sort_index,
                type: 'number',
                name: 'sortIndex',
                description:
                  translate.display_order_description ||
                  '「顯示次序」指的是本項目在前端網站或POS上呈現的次序，系統以升序排列，數字越小，顯示越靠前。',
                md: 6,
              },
              {
                label: translate.dine_in_duration || '堂食時間',
                type: 'number',
                name: 'metadata.timeLimit',
                md: 6,
                inputProps: {
                  step: 1,
                  min: 1,
                },
                disabled: ({ values }) => !!values?.metadata?.ignoreTimeLimited,
                InputProps: ({ values, setFieldValue }) => ({
                  endAdornment: (
                    <InputAdornment position="end" style={{ width: 400 }}>
                      <Grid container spacing={3} alignItems="center" justifyContent={'flex-end'}>
                        <Grid item>
                          <Typography>{translate.minutes}</Typography>
                        </Grid>
                        <Grid item>
                          <CheckboxField
                            checked={!!+values?.metadata?.ignoreTimeLimited}
                            onChange={(e) => {
                              setFieldValue('metadata', {
                                ...values?.metadata,
                                ignoreTimeLimited: !!e?.target?.checked ? 1 : 0,
                              });
                            }}
                            textVariant="body"
                            text={translate.unlimited || '無限時'}
                          />
                        </Grid>
                      </Grid>
                    </InputAdornment>
                  ),
                }),
              },
              {
                label: translate.dine_in_duration_reminder || '堂食時間提示',
                type: 'number',
                name: 'metadata.timeLimitTips',
                md: 6,
                disabled: ({ values }) => !!values?.metadata?.ignoreTimeLimited,
                inputProps: {
                  step: 1,
                  min: 1,
                },
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography>{translate.minutes}</Typography>
                    </InputAdornment>
                  ),
                },
              },
              {
                label: translate.minimum_total_order || '全單最低消費',
                type: 'number',
                name: 'metadata.minimumConsumption',
                md: 6,
                inputProps: {
                  step: 2,
                  min: 0,
                },
                disabled: ({ values }) => !!values?.metadata?.ignoreMinimumConsumption,
                InputProps: ({ values, setFieldValue }) => ({
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>{translate.transaction_subtotal}$</Typography>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div>
                        <CheckboxField
                          checked={!!+values?.metadata?.ignoreMinimumConsumption}
                          onChange={(e) => {
                            setFieldValue('metadata', {
                              ...values?.metadata,
                              ignoreMinimumConsumption: !!e?.target?.checked ? 1 : 0,
                            });
                          }}
                          textVariant="body"
                          text={translate.unlimited || '無限制'}
                        />
                      </div>
                    </InputAdornment>
                  ),
                }),
              },
              {
                label: translate.minimum_order_reminder || '最低消費提示',
                type: 'number',
                name: 'metadata.minimumConsumptionTips',
                md: 6,
                disabled: ({ values }) => !!values?.metadata?.ignoreMinimumConsumption,
                inputProps: {
                  step: 2,
                  min: 0,
                },
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>{translate.transaction_subtotal}$</Typography>
                    </InputAdornment>
                  ),
                },
              },
              {
                label: translate.minimum_per_person_spending || '人均最低消費',
                type: 'number',
                name: 'metadata.minimumConsumptionPer',
                md: 6,
                disabled: ({ values }) => !!values?.metadata?.ignoreMinimumConsumptionPer,
                inputProps: {
                  step: 1,
                  min: 1,
                },
                InputProps: ({ values, setFieldValue }) => ({
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>{translate.per_person || '每人'}$</Typography>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div>
                        <CheckboxField
                          checked={!!+values?.metadata?.ignoreMinimumConsumptionPer}
                          onChange={(e) => {
                            setFieldValue('metadata', {
                              ...values?.metadata,
                              ignoreMinimumConsumptionPer: !!e?.target?.checked ? 1 : 0,
                            });
                          }}
                          textVariant="body"
                          text={translate.unlimited || '無限制'}
                        />
                      </div>
                    </InputAdornment>
                  ),
                }),
              },
              {
                label: translate.minimum_per_person_spending_reminder || '人均最低消費提示',
                type: 'number',
                name: 'metadata.minimumConsumptionPerTips',
                md: 6,
                disabled: ({ values }) => !!values?.metadata?.ignoreMinimumConsumptionPer,
                inputProps: {
                  step: 1,
                  min: 1,
                },
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>$</Typography>
                    </InputAdornment>
                  ),
                },
              },
              {
                display: () => {
                  return this.state.id;
                },
                label: translate.static_ordering_qrcode || '靜態點餐二維碼',
                render: () => {
                  const qrcode = `${REACT_APP_FNB_QR_URL}/startFromTable?tableId=${this.state.id}`;
                  return (
                    <img
                      alt={qrcode}
                      src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${qrcode}`}
                      style={{ display: 'inline' }}
                    />
                  );
                },
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async ({ sortIndex, metadata: _metadata }) => {
    const metadata = convertMetaArray(_metadata);
    const {
      id,
      gql: { submit },
    } = this.state;
    await client.mutate({
      mutation: submit,
      variables: {
        id,
        input: {
          sortIndex,
          metadata,
        },
      },
    });
    // return true && result.id;
    return true;
  };

  getInitialData({ node } = {}) {
    const { name, sortIndex, status = 'AVAILABLE', updatedAt, metadata, shop } = node || {};
    return {
      name,
      sortIndex,
      status,
      updatedAt,
      metadata: convertMetaObject(metadata),
      shop: {
        ...shop,
        metadata: convertMetaObject(shop?.metadata),
      },
    };
  }
}

import React from 'react';
import ConnectionPageMarker from '../../../components/ConnectionPageMaker';
import { translate } from '../../../shared/translate';
import { Box, Grid } from '@material-ui/core';
import { InvoiceStatus, RefundStatus } from '../../../shared/omniwe-types';
import StatusBadge from '../../../components/StatusBadge';
import CompleteButton from './CompleteButton';
import RefundButton from './RefundButton';
import { OrderCard } from '../../../components/OrderCard';
import { GET_QUERY } from './query';
import { GET_QUERY as GET_ORDER } from '../query';
import { JSONParseSafely } from '../../../shared';
import { client } from '../../../shared/apollo';
import OrderRefundButton from './OrderRefundButton';
import CreateInvoiceButton from './CreateInvoiceButton';

class InvoiceCard extends ConnectionPageMarker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    refreshable: false,
    limits: 5,
    fields: [
      {
        title: translate.payment_providers,
        value: ({ paymentMethodV2, credential } = {}) => {
          return paymentMethodV2?.name || credential?.name || paymentMethodV2?.provider;
        },
      },
      {
        title: translate.payment_timestamp || '付款時間',
        fieldName: 'createdAt',
        type: 'datetime',
      },
      {
        title: translate.payment_id || '付款ID',
        value: ({ meta, credential } = {}) => {
          let parsedData = JSONParseSafely(meta, {});
          if (parsedData?.paymentDetail?.id) return parsedData.paymentDetail.id;

          parsedData = JSONParseSafely(parsedData, {});
          return parsedData.paymentDetail.id;
        },
      },
      {
        title: translate.sales_amount,
        width: 100,
        fieldName: 'total',
        type: 'price',
      },
      {
        title: translate.refunded_amount,
        width: 100,
        fieldName: 'totalRefund',
        type: 'price',
      },
      {
        title: translate.status,
        width: 150,
        align: 'center',
        fieldName: (v) => (!!v?.refundStatus ? 'refundStatus' : 'status'),
        type: 'option',
        options: (v) => {
          return Object.keys(!!v?.refundStatus ? RefundStatus : InvoiceStatus).map((key) => ({
            label: <StatusBadge status={key} statusObject={!!v?.refundStatus ? RefundStatus : InvoiceStatus} />,
            value: key,
          }));
        },
      },
      {
        title: translate.other_actions,
        width: 100,
        render: (invoice) => {
          return (
            <Grid container spacing={1}>
              {(() => {
                if (invoice?.status === 'PENDING' && invoice?.paymentMethodV2?.provider === 'MANUAL')
                  return (
                    <Grid item>
                      <CompleteButton
                        {...this.props}
                        invoice={invoice}
                        onInvoiceCompleted={this._temporarySetOrderPaymentCompleted.bind(this)}
                      />
                    </Grid>
                  );

                return null;
              })()}
              {(() => {
                const { values } = this.props;

                const invoiceLevelRefundedAmount = (this.nodes ?? []).reduce(
                    (reducer, { totalRefund = 0 } = {}) => reducer + totalRefund,
                    0,
                  ),
                  orderLevelRefundedAmount = values?.totalRefund - invoiceLevelRefundedAmount;

                const refundableAmount = ['COMPLETED', 'OVERPAID'].includes(invoice?.status)
                  ? Math.max(invoice.total - invoice.totalRefund, 0)
                  : 0;

                const refundable =
                  values?.status !== 'CANCELLED' && refundableAmount > 0 && orderLevelRefundedAmount === 0;

                if (refundable)
                  return (
                    <Grid item>
                      <RefundButton {...this.props} invoice={invoice} />
                    </Grid>
                  );

                return null;
              })()}
            </Grid>
          );
        },
      },
    ],
  };

  getExtraQueryOptions() {
    const { values: { orderId } = {} } = this.props;
    return {
      skip: !orderId,
    };
  }

  getExtraFetchVariables() {
    const { values: { orderId } = {} } = this.props;
    return {
      id: orderId,
    };
  }

  getData({ node } = {}) {
    const { invoices } = node || {};
    console.log(invoices);
    return invoices;
  }

  renderWrapper(...args) {
    return (
      <Box mb={3}>
        <OrderCard style={{ padding: 0, overflow: 'hidden' }}>{this.renderContent(...args)}</OrderCard>
      </Box>
    );
  }

  _temporarySetOrderPaymentCompleted = async () => {
    const {
      values: { orderId, paymentStatus },
    } = this.props;
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const cache = JSONParseSafely(
        JSON.stringify(
          client.readQuery({
            query: GET_ORDER,
            variables: { id: orderId },
          }),
        ),
      );
      if (['PENDING', 'PROCESSING'].includes(paymentStatus)) cache.node.paymentStatus = 'COMPLETED';
      client.writeQuery({
        query: GET_ORDER,
        variables: { id: orderId },
        data: cache,
      });

      await client.query({
        query: GET_ORDER,
        variables: { id: orderId },
        fetchPolicy: 'network-only',
      });

      this.refetch();
    } catch (e) {
      console.log(e);
    }
  };

  renderExtras() {
    const {
      loading,
      values,
      values: { total, paymentStatus, totalRefund },
    } = this.props;
    const hasNewInvoiceButton = !loading && !['COMPLETED', 'CANCELLED'].includes(paymentStatus);
    const refundable = ['COMPLETED'].includes(paymentStatus) && totalRefund < total;

    return (
      <>
        {!!refundable && <OrderRefundButton order={values} />}
        {!!hasNewInvoiceButton && (
          <>
            <CreateInvoiceButton order={values} onInvoiceCreated={this._temporarySetOrderPaymentCompleted.bind(this)} />
          </>
        )}
      </>
    );
  }
}

export default InvoiceCard;

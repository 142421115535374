import { translate } from 'shared/translate';
import { theme } from '../theme';

export const pendingColor = '#A3A1A1';
export const pendingPayColor = '#EE8E00';
export const completedColor = '#41A445';
export const expiredColor = '#EE8E00';
export const cancelledColor = '#DF3D2A';

export const InvoiceStatus = {
  PENDING: { label: translate['unpaid'], bgColor: pendingColor },
  PROCESSING: { label: translate['payment_pending'], bgColor: pendingPayColor },
  COMPLETED: { label: translate['paid'], bgColor: completedColor },
  CANCELLED: { label: translate['status_cancelled'], bgColor: cancelledColor },
  OVERPAID: { label: translate['paid'], bgColor: completedColor },
};

export const RefundStatus = {
  FULL: { label: translate['full_refund'], bgColor: cancelledColor },
  PARTIAL: { label: translate['partial_refund'], bgColor: cancelledColor },
};

export const StockTransferStatus = {
  PENDING: { label: translate['status_pending'], bgColor: pendingColor },
  IN_TRANSIT: { label: translate['status_sending'], bgColor: pendingPayColor },
  COMPLETED: { label: translate['status_completed'], bgColor: completedColor },
  VOIDED: { label: translate['status_cancelled'], bgColor: cancelledColor },
};

export const ReceivePurchaseStatus = {
  PENDING: { label: translate['status_pending'], bgColor: pendingColor },
  COMPLETED: { label: translate['status_completed'], bgColor: completedColor },
  CANCELLED: { label: translate['status_cancelled'], bgColor: cancelledColor },
  VOIDED: { label: translate['status_voided'], bgColor: cancelledColor },
};

export const StockTakeStatus = {
  PENDING: { label: translate['status_pending'], bgColor: pendingColor },
  PROCESSING: { label: translate['status_processing'], bgColor: theme.palette.warning.light },
  FAILED: { label: translate['status_fail'], bgColor: cancelledColor },
  COMPLETED: { label: translate['status_completed'], bgColor: completedColor },
  CANCELLED: { label: translate['status_rejected'], bgColor: cancelledColor },
};

export const StockAdjustmentStatus = {
  PENDING: { label: translate['status_pending'], bgColor: pendingColor },
  COMPLETED: { label: translate['status_completed'], bgColor: completedColor },
  CANCELLED: { label: translate['status_rejected'], bgColor: cancelledColor },
  VOIDED: { label: translate['status_voided'], bgColor: cancelledColor },
};

export const OrderStatus = {
  PENDING: { label: translate['status_pending'], bgColor: pendingColor },
  PROCESSING: { label: translate['status_processing'], bgColor: pendingPayColor },
  COMPLETED: { label: translate['status_finished'], bgColor: completedColor },
  CANCELLED: { label: translate['status_cancelled'], bgColor: cancelledColor },
};

export const ReturnNoteStatus = {
  PENDING: { label: translate.status_return_pending || '等待退貨', bgColor: pendingColor },
  APPROVED: { label: translate.awaiting_confirmation, bgColor: pendingPayColor },
  COMPLETED: { label: translate.completed, bgColor: completedColor },
  REJECTED: { label: translate.status_rejected, bgColor: cancelledColor },
  CANCELLED: { label: translate.status_cancelled, bgColor: cancelledColor },
};

export const ShopFormRecordStatus = {
  PENDING: { label: translate['status_pending'], bgColor: pendingColor },
  APPROVED: { label: translate['status_approved'], bgColor: theme.palette.primary.main },
  REJECTED: { label: translate['status_rejected'], bgColor: cancelledColor },
};

export const ShippingStatus = {
  PENDING: { label: translate['status_pending'], bgColor: pendingColor },
  PROCESSING: { label: translate['status_processing'], bgColor: pendingPayColor },
  PARTIAL: { label: translate['partial_shipped_out'], bgColor: theme.palette.info.light },
  SHIPPING: { label: translate['shipped_out'], bgColor: theme.palette.info.dark },
  COMPLETED: { label: translate['status_delivered'], bgColor: completedColor },
};

export const InvoiceCreditNoteStatus = {
  PENDING: { label: translate['status_pending'], bgColor: pendingColor },
  COMPLETED: { label: translate['status_completed'], bgColor: completedColor },
};

export const DeliveryNoteStatus = {
  PENDING: { label: translate['status_pending'], bgColor: pendingColor },
  PROCESSING: { label: translate['status_processing'], bgColor: pendingPayColor },
  // PREPARING: { label: translate['status_preparing'], bgColor: '#ffed71' },
  // TRANSIT: { label: translate['status_transit'], bgColor: '#8ab0ff' },
  COMPLETED: { label: translate['status_completed'], bgColor: completedColor },
  // LOST: { label: translate['status_lost'], bgColor: '#ff00e5' },
  CANCELLED: { label: translate['status_cancelled'], bgColor: cancelledColor },
  VOIDED: { label: translate['status_voided'], bgColor: theme.palette.error.dark },
};

export const TableStatus = {
  PENDING: { label: translate['status_available'], bgColor: theme.palette.primary.main },
  PROCESSING: { label: translate['status_occupied'], bgColor: pendingPayColor },
  COMPLETED: { label: translate['status_requires_cleaning'], bgColor: theme.palette.warning.dark },
  CANCELLED: { label: translate['status_unavailable'], bgColor: cancelledColor },
};

export const DeliveryMethodProvider = {
  // MANUAL: { label: translate.manual },
  PICKUP: { label: translate.pick_up },
  DELIVERY: { label: translate.delivery },
  // HK_POST: { label: translate['hk-post'] },
  SF_EXPRESS: { label: translate.sf_express },
  SHIPANY_SF_EXPRESS: { label: `ShipAny - ${translate.sf_express || '順豐速運'}` },
  SHIPANY_SF_PICKUP: {
    label: `ShipAny - ${translate.sf_pick_up_point || '順豐自取點'}`,
  },
  // SF_PICKUP: { label: translate.sf_pickup },
  // HK_POST_PICKUP: { label: `${translate['hk-post']} ${translate.pick_up}` },
  FREIGHT_AMIGO: { label: `FreightAmigo - ${translate.delivery}` },
  FREIGHT_AMIGO_PICKUP: { label: `FreightAmigo - ${translate.shipAny_sf_ef_locker}` },
};

export const SFDeliveryMethodProvider = {
  SHIPANY_SF_SEVEN: {
    label: translate.shipAny_sf_seven,
    value: '7-Eleven (SF Express)',
  },
  SHIPANY_SF_OK: {
    label: translate.shipAny_sf_ok,
    value: 'Circle K (SF Express)',
  },
  SHIPANY_SF_STATION: {
    label: translate.shipAny_sf_station,
    value: 'SF Business Station',
  },
  SHIPANY_SF_EXPRESS: {
    label: translate.shipAny_sf_express,
    value: 'Others (SF Express)',
  },
  SHIPANY_SF_SHELL_STATION: {
    label: translate.shipAny_sf_shell_station,
    value: 'Shell Station (SF Express)',
  },
  SHIPANY_SF_STORE: {
    label: translate.shipAny_sf_store,
    value: 'SF Store',
  },
  SHIPANY_SF_EF_LOCKER: {
    label: translate.shipAny_sf_ef_locker,
    value: 'EF Locker',
  },
  SHIPANY_SF_KE_LOCKER: {
    label: translate.shipAny_sf_ke_locker,
    value: 'KE Locker',
  },
};

export const FreightAmigoSFDeliveryMethodProvider = {
  SHIPANY_SF_EF_LOCKER: {
    label: translate.shipAny_sf_ef_locker,
    value: 'EF Locker',
  },
};

export const UserCouponStatus = {
  ACTIVE: { label: translate.not_used, bgColor: pendingColor },
  USED: { label: translate.used, bgColor: completedColor },
  EXPIRED: { label: translate.expired, bgColor: expiredColor },
  CANCELLED: { label: translate.deactivated, bgColor: cancelledColor },
};

export const AppointmentStatus = {
  PENDING: { label: translate['wait_confirmation'], bgColor: pendingPayColor },
  CONFIRMED: { label: translate['pending'], bgColor: pendingColor },
  CANCELLED: { label: translate['status_cancelled'], bgColor: cancelledColor },
  COMPLETED: { label: translate['attended'], bgColor: completedColor },
};

export const AttendanceStatus = {
  PENDING: { label: translate['status_approved'], bgColor: pendingPayColor },
  ATTENDED: { label: translate['attended'], bgColor: completedColor },
  NO_SHOW: { label: translate['absent'], bgColor: cancelledColor },
};

export const StaffAttendanceStatus = {
  IN: { label: translate['check_in'], bgColor: completedColor },
  OUT: { label: translate['check_out'], bgColor: pendingColor },
};

export const SitemapStatus = {
  USING: { label: translate['active'], bgColor: completedColor },
  PENDING: { label: translate['backup'], bgColor: pendingColor },
};
